import { ElMessage } from 'element-plus'

const tipsMsg = class  {
    constructor(message,type) {
        this.message=message;
        this.type=type;
    }

    tipmsg(){
        ElMessage({
            message:this.message,
            type:this.type,
            duration:1500
        })
    }
}
export default tipsMsg;
