import {createRouter,createWebHashHistory} from "vue-router"


const routes=[
    {//登录界面
        path:'/',
        name:'login',
        component:()=>import(/*webpackChunkName:'Login'*/'@/page/login/login')
    },
    {//首页界面
        path:'/index',
        name:'index',
        component:()=>import(/*webpackChunkName:'Index'*/'@/page/index/index'),
        redirect:'/dataDetail',//定位到指定页面
        children:[
            {//商品列表界面
                path:'/goods-page',
                name:'goods-page',
                component:()=>import(/*webpackChunkName:'Goods'*/'@/page/goods-page/goods'),
                // meta: { keepAlive: true },

            },
            {//添加商品
                path:'/create-goods',
                name:'create',
                component:()=>import(/*webpackChunkName:'Create-goods'*/'@/page/create-goods/create')
            },
            {//更新商品
                path:'/create-goods/',
                name:'update-goods',
                component:()=>import(/*webpackChunkName:'Create-goods'*/'@/page/create-goods/create')
            },
            {//添加商品
                path:'/dataDetail',
                name:'dataDetail',
                component:()=>import(/*webpackChunkName:'DataDetail'*/'@/page/dataDetail/data-detail')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router;