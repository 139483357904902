//放置所有的请求接口

const url='https://www.zuolingyoushi.com/mini'; //生产地址
// const url='https://zuolingyoushi.l2.ttut.cc/mini'; //测试地址
// const url='https://zuolingyoushi.free.svipss.top/mini';
const urls=class  {
    static m(){
        //登录接口
        const login=`${url}/zlysManageUser/doLogin`
        const createGoods=`${url}/manageGoods/createGoods`
        const goodsPageList=`${url}/manageGoods/pageList`
        const goodsDelete=`${url}/manageGoods/delete`

        return {
            login,createGoods,goodsPageList,goodsDelete
        }
    }
}
export default urls;